import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Layout from "../components/layout"
import {
    Container,
    Box,
    Space,
    HomepageImage,
    FlexList,
} from "../components/ui"

export interface BlogAuthor {
    id: string
    name: string
    avatar: HomepageImage
}

export interface ArtCategoryProps {
    data: {
        datoCmsArtCategory: {
            id: string
            title: string
        }
        allDatoCmsArtwork: {
            nodes: {
                id: string
                title: string
                slug: string
                image: {
                    gatsbyImageData: IGatsbyImageData
                }
                year: number
            }[]
        }
    }
}

export default function ArtCategory(props: ArtCategoryProps) {
    return (
        <Layout title={`kGomes Art - ${props.data.datoCmsArtCategory.title}`}>
            <Container>
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                    <Masonry gutter="10px">
                        {
                            props.data.allDatoCmsArtwork.nodes
                                .sort((x, y) => y.year - x.year)
                                .map((artwork) =>
                                    <Box key={artwork.id}>
                                        <Link to={`/art/${artwork.slug}`}>
                                            <GatsbyImage image={artwork.image.gatsbyImageData} alt="" />
                                        </Link>
                                    </Box>
                                )
                        }
                    </Masonry>
                </ResponsiveMasonry>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query ArtCategoryContent($id: String!) {
        datoCmsArtCategory(id: {eq: $id}) {
            id
            title
        }
        allDatoCmsArtwork(filter: {category: {elemMatch: {id: {eq: $id}}}}) {
            nodes {
                id
                title
                slug
                image {
                    gatsbyImageData(width: 400)
                }
                year
            }
        }
    }
`
